<template>
  <div>
    <b-card title="Inicio/Cierre de Operacion">
      <b-card-text>
        <b-row>
          <b-col cols="12" md="6">
            <div class="demo-inline-spacing" v-if="status">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                :disabled="mixing.apertura && totalItems > 1"
                @click="
                  accionModalCajaApertura = mixing.apertura
                    ? 'actualizar'
                    : 'aperturar'
                "
              >
                <feather-icon icon="CircleIcon" class="mr-50" />
                <span class="align-middle">INICIO DE OPERACION</span>
              </b-button>

              <b-button
                v-if="!(mixing.corte == mixing.apertura)"
                :disabled="mixing.corte == mixing.apertura"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="$refs.modalCajaCierreRef.abrir_cierre_caja()"
              >
                <feather-icon icon="XCircleIcon" class="mr-50" />
                <span class="align-middle">CIERRE DE OPERACION</span>
              </b-button>

              <b-button
                v-if="mixing.corte == true"
                :disabled="mixing.corte == false"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="abrirCaja(itemCorte.id)"
              >
                <feather-icon icon="CircleIcon" class="mr-50" />
                <span class="align-middle">INICIAR OPERACION</span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                size="sm"
                @click="listar"
              >
                <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                <span class="align-middle">RECARGAR</span>
              </b-button>
            </div>
          </b-col>
          <b-col cols="12" md="6" class="mt-1 mt-md-0">
            <b-row class="justify-content-end">
              <div class="demo-inline-spacing">
                <b-form-group
                  class="mb-0 align-items-center justify-content-end"
                >
                  <b-button
                    v-if="$hascan('movimiento-caja')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="dark"
                    size="sm"
                    @click="$refs.modalCajaMovimientoRef.abrir()"
                    :disabled="mixing.corte == mixing.apertura"
                  >
                    <feather-icon icon="EyeIcon" class="mr-50" />
                    <span class="align-middle">MOVIMIENTO DE CAJA</span>
                  </b-button>
                </b-form-group>
                <b-form-group
                  label=""
                  label-align-sm="right"
                  label-for="filterInput"
                  class="mb-0"
                >
                  <b-form-input
                    v-model="searchQuery"
                    :formatter="textFormatter"
                    @input="updateSearchQuery"
                    type="search"
                    size="sm"
                    placeholder="Buscar.."
                  />
                </b-form-group>
              </div>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text>
        <Listado
          ref="listadoRef"
          :items="items"
          :loading="loading"
          @selectItemCaja="selectItemCaja"
        />
      </b-card-text>
    </b-card>
    <ModalFormCajaApertura
      :accionModalCajaApertura="accionModalCajaApertura"
      :itemApertura="itemApertura"
      @cerrarModalCajaApertura="cerrarModalCajaApertura"
      @actualizarCajaApertura="actualizarCajaApertura"
    />
    <ModalFormCajaCierre
      ref="modalCajaCierreRef"
      @actualizarCajaCierre="actualizarCajaCierre"
    />
    <ModalFormCajaMovimiento
      ref="modalCajaMovimientoRef"
      @actualizarCajaApertura="actualizarCajaApertura"
    />
  </div>
</template>

<script>
import store from "@/store/index";
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Listado from "../views/Listado.vue";
import ModalFormCajaApertura from "../components/ModalFormCajaApertura.vue";
import ModalFormCajaCierre from "../components/ModalFormCajaCierre.vue";
import ModalFormCajaMovimiento from "../components/ModalFormCajaMovimiento.vue";
import CajaServices from "../services/index";
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    Listado,
    BRow,
    BCol,
    ModalFormCajaApertura,
    ModalFormCajaCierre,
    ModalFormCajaMovimiento,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      accionModalCajaApertura: "",
      accionModalCajaCorte: "",
      itemApertura: "",
      itemCorte: "",
      items: [],
      item: [],
      apertura: "",
      corte: "",
      itemApertura: [],
      itemCorte: [],
      totalItems: 0,
      buttonDisabled: true,
      searchQuery: "",
      status: false,
    };
  },
  created() {},
  watch: {},
  methods: {
    async listar() {
      this.loading = true;
      try {
        const response = await CajaServices.get(
          this.mixing.caja ? this.mixing.caja.id : null
        );
        this.listaFormateado(response.data);
        // this.items = response.data;
        this.item = [];
        this.itemApertura = this.mixing.caja; // TODO: revisar
        this.itemCorte = this.mixing.cajaCorte; // TODO: revisar
        this.buttonDisabled = true;
        this.loading = false;
        this.totalItems = this.items.length;
        this.status = true;
      } catch (err) {
        this.loading = false;
        this.itemApertura = this.mixing.caja; // TODO: revisar
        this.itemCorte = this.mixing.cajaCorte; // TODO: revisar
        this.buttonDisabled = true;
        this.status = true;
        this.item = [];
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    listaFormateado(items) {
      let saldo = 0;
      for (let index = 0; index < items.length; index++) {
        if (index == 0) {
          saldo = parseFloat(items[index].saldo_total).toFixed(2);
        } else if (items[index].transaccion != "CORTE DE CAJA") {
          if (items[index].tipo == "INGRESO") {
            saldo = (
              parseFloat(saldo) + parseFloat(items[index].saldo_total)
            ).toFixed(2);
          } else {
            saldo = (
              parseFloat(saldo) - parseFloat(items[index].saldo_total)
            ).toFixed(2);
          }
          items[index].saldo_total = saldo;
        }
      }
      this.items = items;
    },

    // Emit modal apertura de caja
    actualizarCajaApertura() {
      this.listar();
      this.accionModalCajaApertura = "";
      this.item = [];
      this.buttonDisabled = true;
    },
    cerrarModalCajaApertura() {
      this.accionModalCajaApertura = "";
    },

    // Emit modal cierre de caja
    actualizarCajaCierre() {
      this.listar();
    },

    selectItemCaja(item, bool) {
      this.item = item;
      this.buttonDisabled = bool;
    },
    async abrirCaja(id) {
      await this.$bvModal
        .msgBoxConfirm(
          "Confirme si esta seguro de volver a iniciar la operacion?",
          {
            title: "INICIAR OPERACION ?",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Confirmar",
            cancelTitle: "Cancelar",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
          }
        )
        .then((value) => {
          if (value) {
            this.volverAbrirCaja(id);
          }
        });
    },
    async volverAbrirCaja(id) {
      try {
        await CajaServices.volverAbrirCaja(id);
        store
          .dispatch("auth/attempt", localStorage.getItem("token"))
          .then(() => {});
        this.listar();
      } catch (err) {
        this.$bvToast.toast(err.response.data.error, {
          title: "ADVERTENCIA!",
          variant: "danger",
          solid: false,
        });
        store
          .dispatch("auth/attempt", localStorage.getItem("token"))
          .then(() => {});
        this.listar();
      }
    },
    updateSearchQuery(val) {
      this.$refs.listadoRef.updateSearchQuery(val);
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
  },
  mounted() {
    this.listar();
  },
};
</script>

<style lang="scss" scoped>
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
</style>
