<template>
  <b-modal
    id="modal-from-cierre-caja"
    ref="modal"
    title="CIERRE DE OPERACION"
    v-model="modalFromCierreCaja"
    no-close-on-backdrop
    no-close-on-esc
    ok-title="Aceptar"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    button-size="sm"
    scrollable
    size="lg"
    @cancel="cerrarModal"
    @close="cerrarModal"
    @ok.prevent="validar"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="12" lg="6" md="6" sm="6">
            <b-form-group label="USUARIO/CAJERO:">
              <b-form-input
                size="sm"
                :value="caja ? caja.caja.usuario : ''"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="3" md="3" sm="3">
            <b-form-group label="FECHA INICIO:">
              <b-form-input
                size="sm"
                :value="caja ? caja.caja.fecha : ''"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="3" md="3" sm="3">
            <b-form-group label="BASE EFECTIVO:">
              <b-form-input
                size="sm"
                :value="caja ? caja.caja.monto : ''"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-table-simple
              :bordered="true"
              class="rounded-bottom mb-0"
              :no-border-collapse="true"
              small
              responsive
            >
              <b-thead head-variant="light">
                <b-tr>
                  <b-th width="20%" class="text-center"></b-th>
                  <b-th width="16%" class="text-center">Egreso</b-th>
                  <b-th width="16%" class="text-center">Ingreso</b-th>
                  <b-th width="16%" class="text-center">Calculado</b-th>
                  <b-th width="5%" class="text-center"></b-th>
                  <b-th width="16%" class="text-center">Contado</b-th>
                  <b-th width="16%" class="text-center">Diferencia</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-th class="text-center">EFECTIVO</b-th>
                  <b-td class="text-center">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="efectivoEgreso"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group>
                  </b-td>
                  <b-td class="text-center">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="efectivoIngreso"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group>
                  </b-td>
                  <b-td class="text-right">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="totalEfectivo"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group>
                  </b-td>
                  <b-td class="text-center">
                    <b-button
                      v-b-tooltip.hover
                      @click="modalArqueoCaja = true"
                      size="sm"
                      title="Arqueo de caja"
                      variant="gradient-primary"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="btn-icon"
                    >
                      <feather-icon icon="GridIcon" />
                    </b-button>
                  </b-td>
                  <b-td class="text-right">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        onClick="this.select();"
                        disabled
                        lazy-formatter
                        :formatter="contadoNumberFormatter"
                        v-model="form.contado"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group>
                  </b-td>
                  <b-td class="text-right">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        :class="form.diferencia < 0 ? 'text-danger' : ''"
                        disabled
                        :value="(form.diferencia = calcular)"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group>
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th class="text-center">TARJETA</b-th>
                  <b-td class="text-center">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="tarjetaEgreso"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group>
                  </b-td>
                  <b-td class="text-center">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="tarjetaIngreso"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group>
                  </b-td>
                  <b-td class="text-center">
                    -
                    <!-- <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="totalTarjeta"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group> -->
                  </b-td>
                  <b-th class="text-center"></b-th>
                  <b-td class="text-center">-</b-td>
                  <b-td class="text-center">-</b-td>
                </b-tr>

                <b-tr>
                  <b-th class="text-center">TRANSACION BANCARIA</b-th>
                  <b-td class="text-center">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="qrEgreso"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group>
                  </b-td>
                  <b-td class="text-center">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="qrIngreso"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group>
                  </b-td>
                  <b-td class="text-center">
                    -
                    <!-- <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="totalQr"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group> -->
                  </b-td>
                  <b-th class="text-center"></b-th>
                  <b-td class="text-center">-</b-td>
                  <b-td class="text-center">-</b-td>
                </b-tr>

                <b-tr>
                  <b-th class="text-center">CREDITO</b-th>
                  <b-td class="text-center">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="creditoEgreso"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group>
                  </b-td>
                  <b-td class="text-center">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="creditoIngreso"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group>
                  </b-td>
                  <b-td class="text-center">
                    -
                    <!-- <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="totalCredito"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group> -->
                  </b-td>
                  <b-th class="text-center"></b-th>
                  <b-td class="text-center">-</b-td>
                  <b-td class="text-center">-</b-td>
                </b-tr>

                <b-tr>
                  <b-th class="text-center">OTROS</b-th>
                  <b-td class="text-center">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="otroEgreso"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group>
                  </b-td>
                  <b-td class="text-center">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="otroIngreso"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group>
                  </b-td>
                  <b-td class="text-center">
                    -
                    <!-- <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="totalOtro"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group> -->
                  </b-td>
                  <b-th class="text-center"></b-th>
                  <b-td class="text-center">-</b-td>
                  <b-td class="text-center">-</b-td>
                </b-tr>

                <b-tr>
                  <b-th class="text-center text-primary">TOTAL</b-th>
                  <b-td class="text-center">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="totalEgresos"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group>
                  </b-td>
                  <b-td class="text-center">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="totalIngresos"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group>
                  </b-td>
                  <b-td class="text-center">
                    -
                    <!-- <b-input-group>
                      <b-input-group-prepend is-text>
                        Bs
                      </b-input-group-prepend>
                      <b-form-input
                        disabled
                        :value="0"
                        style="text-align: right"
                        size="sm"
                      />
                    </b-input-group> -->
                  </b-td>
                  <b-td class="text-center"></b-td>
                  <b-td class="text-center">-</b-td>
                  <b-td class="text-center">-</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
        <hr />
      </b-form>
    </validation-observer>

    <b-modal
      id="modal-arqueo-caja"
      v-model="modalArqueoCaja"
      centered
      title="CONTADOR DE CAJA EFECTIVO"
      ok-only
      button-size="sm"
      ok-title="Aceptar"
      @cancel="cerrarModalArqueoCaja"
      @close="cerrarModalArqueoCaja"
      @ok.prevent="validarArqueo"
    >
      <b-card-text>
        <b-table-simple
          :bordered="true"
          class="rounded-bottom mb-0"
          :no-border-collapse="true"
          small
          responsive
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th width="33%" class="text-center">CANTIDAD</b-th>
              <b-th width="33%" class="text-center">DENOMINACION</b-th>
              <b-th width="33%" class="text-center">TOTAL</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>
                <b-input-group>
                  <b-form-input
                    type="number"
                    :value="form.cantidad_200"
                    v-model="form.cantidad_200"
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    onClick="this.select();"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
              <b-td class="text-center">
                <span>X</span>
                <span class="text-bold text-primary">
                  Bs.
                  {{ parseFloat(form.denominacion_200).toFixed(2) }}</span
                >
              </b-td>
              <b-td>
                <b-input-group>
                  <b-input-group-prepend is-text> Bs </b-input-group-prepend>
                  <b-form-input
                    disabled
                    :value="
                      (form.total_200 = (
                        form.cantidad_200 * form.denominacion_200
                      ).toFixed(2))
                    "
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td>
                <b-input-group>
                  <b-form-input
                    type="number"
                    :value="form.cantidad_100"
                    v-model="form.cantidad_100"
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    onClick="this.select();"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
              <b-td class="text-center">
                <span>X</span>
                <span class="text-bold text-primary">
                  Bs.
                  {{ parseFloat(form.denominacion_100).toFixed(2) }}</span
                >
              </b-td>
              <b-td>
                <b-input-group>
                  <b-input-group-prepend is-text> Bs </b-input-group-prepend>
                  <b-form-input
                    disabled
                    :value="
                      (form.total_100 = (
                        form.cantidad_100 * form.denominacion_100
                      ).toFixed(2))
                    "
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td>
                <b-input-group>
                  <b-form-input
                    type="number"
                    :value="form.cantidad_50"
                    v-model="form.cantidad_50"
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    onClick="this.select();"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
              <b-td class="text-center">
                <span>X</span>
                <span class="text-bold text-primary">
                  Bs.
                  {{ parseFloat(form.denominacion_50).toFixed(2) }}</span
                >
              </b-td>
              <b-td>
                <b-input-group>
                  <b-input-group-prepend is-text> Bs </b-input-group-prepend>
                  <b-form-input
                    disabled
                    :value="
                      (form.total_50 = (
                        form.cantidad_50 * form.denominacion_50
                      ).toFixed(2))
                    "
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td>
                <b-input-group>
                  <b-form-input
                    type="number"
                    :value="form.cantidad_20"
                    v-model="form.cantidad_20"
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    onClick="this.select();"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
              <b-td class="text-center">
                <span>X</span>
                <span class="text-bold text-primary">
                  Bs.
                  {{ parseFloat(form.denominacion_20).toFixed(2) }}</span
                >
              </b-td>
              <b-td>
                <b-input-group>
                  <b-input-group-prepend is-text> Bs </b-input-group-prepend>
                  <b-form-input
                    disabled
                    :value="
                      (form.total_20 = (
                        form.cantidad_20 * form.denominacion_20
                      ).toFixed(2))
                    "
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td>
                <b-input-group>
                  <b-form-input
                    type="number"
                    :value="form.cantidad_10"
                    v-model="form.cantidad_10"
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    onClick="this.select();"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
              <b-td class="text-center">
                <span>X</span>
                <span class="text-bold text-primary">
                  Bs.
                  {{ parseFloat(form.denominacion_10).toFixed(2) }}</span
                >
              </b-td>
              <b-td>
                <b-input-group>
                  <b-input-group-prepend is-text> Bs </b-input-group-prepend>
                  <b-form-input
                    disabled
                    :value="
                      (form.total_10 = (
                        form.cantidad_10 * form.denominacion_10
                      ).toFixed(2))
                    "
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td>
                <b-input-group>
                  <b-form-input
                    type="number"
                    :value="form.cantidad_5"
                    v-model="form.cantidad_5"
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    onClick="this.select();"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
              <b-td class="text-center">
                <span>X</span>
                <span class="text-bold text-primary">
                  Bs.
                  {{ parseFloat(form.denominacion_5).toFixed(2) }}</span
                >
              </b-td>
              <b-td>
                <b-input-group>
                  <b-input-group-prepend is-text> Bs </b-input-group-prepend>
                  <b-form-input
                    disabled
                    :value="
                      (form.total_5 = (
                        form.cantidad_5 * form.denominacion_5
                      ).toFixed(2))
                    "
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td>
                <b-input-group>
                  <b-form-input
                    type="number"
                    :value="form.cantidad_2"
                    v-model="form.cantidad_2"
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    onClick="this.select();"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
              <b-td class="text-center">
                <span>X</span>
                <span class="text-bold text-primary">
                  Bs.
                  {{ parseFloat(form.denominacion_2).toFixed(2) }}</span
                >
              </b-td>
              <b-td>
                <b-input-group>
                  <b-input-group-prepend is-text> Bs </b-input-group-prepend>
                  <b-form-input
                    disabled
                    :value="
                      (form.total_2 = (
                        form.cantidad_2 * form.denominacion_2
                      ).toFixed(2))
                    "
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td>
                <b-input-group>
                  <b-form-input
                    type="number"
                    :value="form.cantidad_1"
                    v-model="form.cantidad_1"
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    onClick="this.select();"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
              <b-td class="text-center">
                <span>X</span>
                <span class="text-bold text-primary">
                  Bs.
                  {{ parseFloat(form.denominacion_1).toFixed(2) }}</span
                >
              </b-td>
              <b-td>
                <b-input-group>
                  <b-input-group-prepend is-text> Bs </b-input-group-prepend>
                  <b-form-input
                    disabled
                    :value="
                      (form.total_1 = (
                        form.cantidad_1 * form.denominacion_1
                      ).toFixed(2))
                    "
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td>
                <b-input-group>
                  <b-form-input
                    type="number"
                    :value="form.cantidad_0_5"
                    v-model="form.cantidad_0_5"
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    onClick="this.select();"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
              <b-td class="text-center">
                <span>X</span>
                <span class="text-bold text-primary">
                  Bs.
                  {{ parseFloat(form.denominacion_0_5).toFixed(2) }}</span
                >
              </b-td>
              <b-td>
                <b-input-group>
                  <b-input-group-prepend is-text> Bs </b-input-group-prepend>
                  <b-form-input
                    disabled
                    :value="
                      (form.total_0_5 = (
                        form.cantidad_0_5 * form.denominacion_0_5
                      ).toFixed(2))
                    "
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td>
                <b-input-group>
                  <b-form-input
                    type="number"
                    :value="form.cantidad_0_2"
                    v-model="form.cantidad_0_2"
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    onClick="this.select();"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
              <b-td class="text-center">
                <span>X</span>
                <span class="text-bold text-primary">
                  Bs.
                  {{ parseFloat(form.denominacion_0_2).toFixed(2) }}</span
                >
              </b-td>
              <b-td>
                <b-input-group>
                  <b-input-group-prepend is-text> Bs </b-input-group-prepend>
                  <b-form-input
                    disabled
                    :value="
                      (form.total_0_2 = (
                        form.cantidad_0_2 * form.denominacion_0_2
                      ).toFixed(2))
                    "
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td>
                <b-input-group>
                  <b-form-input
                    type="number"
                    :value="form.cantidad_0_1"
                    v-model="form.cantidad_0_1"
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    onClick="this.select();"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
              <b-td class="text-center">
                <span>X</span>
                <span class="text-bold text-primary">
                  Bs.
                  {{ parseFloat(form.denominacion_0_1).toFixed(2) }}</span
                >
              </b-td>
              <b-td>
                <b-input-group>
                  <b-input-group-prepend is-text> Bs </b-input-group-prepend>
                  <b-form-input
                    disabled
                    :value="
                      (form.total_0_1 = (
                        form.cantidad_0_1 * form.denominacion_0_1
                      ).toFixed(2))
                    "
                    lazy-formatter
                    :formatter="numberFormatter"
                    style="text-align: right"
                    size="sm"
                  />
                </b-input-group>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <hr />
        <b-row class="mb-0-5">
          <b-col cols="4"></b-col>
          <b-col cols="4" class="text-center"> TOTAL Bs.</b-col>
          <b-col cols="4">
            <b-input-group>
              <b-form-input
                disabled
                :value="calcularTotalDenominacion"
                style="text-align: right"
                size="sm"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </b-modal>
</template>
<script>
import store from "@/store/index";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CajaServices from "../services/index";
import {
  BModal,
  BButton,
  VBModal,
  BAlert,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BFormRadioGroup,
  BFormCheckbox,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BFormTextarea,
  BCardText,
  BCard,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";

export default {
  components: {
    BButton,
    BModal,
    BAlert,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BFormRadioGroup,
    BFormCheckbox,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BFormTextarea,
    BCardText,
    BCard,
  },
  props: ["accionModalCajaCorte"],
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        diferencia: "",
        contado: "0.00",
        calculado: "0.00",
        caja_id: "",

        cantidad_200: "0",
        denominacion_200: 200,
        total_200: "0.00",

        cantidad_100: "0",
        denominacion_100: 100,
        total_100: "0.00",

        cantidad_50: "0",
        denominacion_50: 50,
        total_50: "0.00",

        cantidad_20: "0",
        denominacion_20: 20,
        total_20: "0.00",

        cantidad_10: "0",
        denominacion_10: 10,
        total_10: "0.00",

        cantidad_5: "0",
        denominacion_5: 5,
        total_5: "0.00",

        cantidad_2: "0",
        denominacion_2: 2,
        total_2: "0.00",

        cantidad_1: "0",
        denominacion_1: 1,
        total_1: "0.00",

        cantidad_0_5: "0",
        denominacion_0_5: 0.5,
        total_0_5: "0.00",

        cantidad_0_2: "0",
        denominacion_0_2: 0.2,
        total_0_2: "0.00",

        cantidad_0_1: "0",
        denominacion_0_1: 0.1,
        total_0_1: "0.00",
      },
      caja: "",

      totalEfectivo: "0.00",
      totalTarjeta: "0.00",
      totalQr: "0.00",
      totalCredito: "0.00",
      totalOtro: "0.00",

      totalIngresos: "0.00",
      totalEgresos: "0.00",

      contado: "0.00",
      diferencia: "0.00",

      // ingreso y egreso
      efectivoIngreso: "0.00",
      efectivoEgreso: "0.00",

      tarjetaIngreso: "0.00",
      tarjetaEgreso: "0.00",

      qrIngreso: "0.00",
      qrEgreso: "0.00",

      creditoIngreso: "0.00",
      creditoEgreso: "0.00",

      otroIngreso: "0.00",
      otroEgreso: "0.00",

      farmacia: [],
      consulta: [],
      laboratorio: [],
      procedimiento: [],
      apertura: [],
      saldo: 0,
      efectivoSinBase: 0,

      required,
      submitted: false,
      errores: [],

      modalFromCierreCaja: false,
      modalArqueoCaja: false,
    };
  },
  computed: {
    calcular: function () {
      let resultado = 0;
      resultado = (this.form.contado - this.totalEfectivo).toFixed(2);
      return resultado;
    },
    calcularTotalDenominacion: function () {
      let resultado = 0;
      resultado =
        parseFloat(this.form.total_200) +
        parseFloat(this.form.total_100) +
        parseFloat(this.form.total_50) +
        parseFloat(this.form.total_20) +
        parseFloat(this.form.total_10) +
        parseFloat(this.form.total_5) +
        parseFloat(this.form.total_2) +
        parseFloat(this.form.total_1) +
        parseFloat(this.form.total_0_5) +
        parseFloat(this.form.total_0_2) +
        parseFloat(this.form.total_0_1);
      return resultado.toFixed(2);
    },
  },
  watch: {},
  methods: {
    async validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            this.caja_corte();
          }
        }
      });
    },
    async abrir_cierre_caja() {
      try {
        const response = await CajaServices.getCierreCaja(
          this.mixing.caja ? this.mixing.caja.id : null
        );
        await store
          .dispatch("auth/attempt", localStorage.getItem("token"))
          .then(() => {});

        this.modalFromCierreCaja = true;
        this.caja = response.data;
        this.totalEfectivo = parseFloat(this.caja.efectivo).toFixed(2);
        this.totalTarjeta = parseFloat(this.caja.tarjeta).toFixed(2);
        this.totalQr = parseFloat(this.caja.qr).toFixed(2);
        this.totalCredito = parseFloat(this.caja.credito).toFixed(2);
        this.totalOtro = parseFloat(this.caja.otro).toFixed(2);

        this.efectivoIngreso = parseFloat(this.caja.efectivoIngreso).toFixed(2);
        this.efectivoEgreso = parseFloat(this.caja.efectivoEgreso).toFixed(2);

        this.qrIngreso = parseFloat(this.caja.qrIngreso).toFixed(2);
        this.qrEgreso = parseFloat(this.caja.qrEgreso).toFixed(2);

        this.tarjetaIngreso = parseFloat(this.caja.tarjetaIngreso).toFixed(2);
        this.tarjetaEgreso = parseFloat(this.caja.tarjetaEgreso).toFixed(2);

        this.creditoIngreso = parseFloat(this.caja.creditoIngreso).toFixed(2);
        this.creditoEgreso = parseFloat(this.caja.creditoEgreso).toFixed(2);

        this.otroIngreso = parseFloat(this.caja.otroIngreso).toFixed(2);
        this.otroEgreso = parseFloat(this.caja.otroEgreso).toFixed(2);

        this.totalIngresos = parseFloat(this.caja.totalIngresos).toFixed(2);
        this.totalEgresos = parseFloat(this.caja.totalEgresos).toFixed(2);
      } catch (err) {
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    cerrarModal() {
      this.limpiarForm();
      this.$emit("actualizarCajaCierre");
    },
    cerrarModalArqueoCaja() {
      this.modalArqueoCaja = false;
    },

    actualizarCajaCierre() {
      this.limpiarForm();
      this.$emit("actualizarCajaCierre");
      this.submitted = false;
      this.form = {
        diferencia: "",
        contado: "0.00",
        calculado: "0.00",
        caja_id: "",

        cantidad_200: "0",
        denominacion_200: 200,
        total_200: "0.00",

        cantidad_100: "0",
        denominacion_100: 100,
        total_100: "0.00",

        cantidad_50: "0",
        denominacion_50: 50,
        total_50: "0.00",

        cantidad_20: "0",
        denominacion_20: 20,
        total_20: "0.00",

        cantidad_10: "0",
        denominacion_10: 10,
        total_10: "0.00",

        cantidad_5: "0",
        denominacion_5: 5,
        total_5: "0.00",

        cantidad_2: "0",
        denominacion_2: 2,
        total_2: "0.00",

        cantidad_1: "0",
        denominacion_1: 1,
        total_1: "0.00",

        cantidad_0_5: "0",
        denominacion_0_5: 0.5,
        total_0_5: "0.00",

        cantidad_0_2: "0",
        denominacion_0_2: 0.2,
        total_0_2: "0.00",

        cantidad_0_1: "0",
        denominacion_0_1: 0.1,
        total_0_1: "0.00",
      };
    },

    async caja_corte() {
      this.form.calculado = this.totalEfectivo;
      this.form.caja_id = this.mixing.caja.id;
      try {
        const response = await CajaServices.cierreCaja(this.form);
        store
          .dispatch("auth/attempt", localStorage.getItem("token"))
          .then(() => {});
        this.actualizarCajaCierre();
        this.errores = [];
        this.submitted = false;
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
        store
          .dispatch("auth/attempt", localStorage.getItem("token"))
          .then(() => {});
        this.actualizarCajaCierre();
      }
    },

    // limpiar
    limpiarForm() {
      this.$refs.simpleRules.reset();
      this.diferencia = "";
      this.totalEfectivo = "";
      this.modalFromCierreCaja = false;
    },

    validarArqueo() {
      this.form.contado = this.calcularTotalDenominacion;
      this.modalArqueoCaja = false;
    },

    contadoNumberFormatter(value) {
      return (value * 1).toFixed(2);
    },
    numberFormatter(value) {
      return (value * 1).toFixed(0);
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.border-right {
  border-right: 1px solid #ebe9f1;
}
.scroll-area-historia-clinica-lab {
  position: relative;
  margin: 0rem;
  width: 100%;
  padding: 0rem;
  height: calc(var(--vh, 1vh) * 100 - 27rem);
}
@media (max-width: 992px) {
  .border-right {
    border-right: 0px !important;
  }
  .scroll-area-historia-clinica-lab {
    height: auto;
  }
}
#modal-from-cierre-caja {
  .modal-lg {
    max-width: 1000px;
  }
}
</style>