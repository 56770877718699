var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"id":"modal-from-apertura-caja","title":_vm.tituloModal,"no-close-on-backdrop":"","no-close-on-esc":"","ok-title":"Aceptar","cancel-variant":"outline-secondary","cancel-title":"Cerrar","button-size":"sm","scrollable":"","size":"lg"},on:{"cancel":_vm.cerrarModal,"close":_vm.cerrarModal,"ok":function($event){$event.preventDefault();return _vm.validar.apply(null, arguments)}},model:{value:(_vm.modalFromMovimientoCaja),callback:function ($$v) {_vm.modalFromMovimientoCaja=$$v},expression:"modalFromMovimientoCaja"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":"* TIPO ","label-for":"tipo"}},[_c('validation-provider',{attrs:{"name":"tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"tipo","options":_vm.tipoMovimientoItems,"size":"sm","state":errors.length > 0 ? false : null},on:{"change":function($event){return _vm.getPartidas()}},model:{value:(_vm.form.tipo),callback:function ($$v) {_vm.$set(_vm.form, "tipo", $$v)},expression:"form.tipo"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"9"}},[_c('b-form-group',{attrs:{"label":"* CONCEPTO ","label-for":"concepto"}},[_c('validation-provider',{attrs:{"name":"concepto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{key:_vm.form.clasificador_id,staticClass:"style-chooser select-size-sm is-invalid error",class:{ 'is-invalid': errors && errors.length > 0 },attrs:{"label":"nombre","options":_vm.clasificadorItems,"reduce":function (op) { return op.id; },"selectable":function (option) { return !option.disabled; }},on:{"input":function($event){return _vm.getDetalle()}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('span',{domProps:{"innerHTML":_vm._s(option.descripcion_tabulada)}})]}}],null,true),model:{value:(_vm.form.clasificador_id),callback:function ($$v) {_vm.$set(_vm.form, "clasificador_id", $$v)},expression:"form.clasificador_id"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"12"}},[_c('b-form-group',{attrs:{"label":"* DETALLE","label-for":"detalle","label-cols":"12"}},[_c('validation-provider',{attrs:{"name":"Detalle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"detalle","disabled":"","size":"sm","value":_vm.form.detalle,"state":errors.length > 0 ? false : null}})]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"* MONTO ","label-for":"monto"}},[_c('validation-provider',{attrs:{"name":"monto","rules":_vm.form.tipo == 'EGRESO'
                  ? ("max_value:" + _vm.totalEfectivoCaja)
                  : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"monto","type":"number","step":"any","disabled":!_vm.form.tipo,"lazy-formatter":"","formatter":_vm.numberFormatter,"state":errors.length > 0 ? false : null,"onClick":"this.select();","size":"sm"},model:{value:(_vm.form.monto),callback:function ($$v) {_vm.$set(_vm.form, "monto", $$v)},expression:"form.monto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"* TOTAL EFECTIVO CAJA ","label-for":"totalEfectivoCaja"}},[_c('b-form-input',{attrs:{"id":"totalEfectivoCaja","type":"number","step":"any","disabled":"","lazy-formatter":"","formatter":_vm.numberFormatter,"value":_vm.totalEfectivoCaja,"size":"sm"}})],1)],1)],1),_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"* TRANSACCION","label-for":"transaccion","label-cols":"12"}},[_c('validation-provider',{attrs:{"name":"Transaccion","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"transaccion","disabled":"","size":"sm","value":_vm.form.transaccion,"lazy-formatter":"","formatter":_vm.textFormatter,"state":errors.length > 0 ? false : null}})]}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"12"}},[_c('b-form-group',{attrs:{"label":"NOTA","label-for":"nota","label-cols":"12"}},[_c('validation-provider',{attrs:{"name":"Nota","rules":"max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"nota","size":"sm","lazy-formatter":"","formatter":_vm.textFormatter,"state":errors.length > 0 ? false : null,"rows":"10"},model:{value:(_vm.form.nota),callback:function ($$v) {_vm.$set(_vm.form, "nota", $$v)},expression:"form.nota"}}),_vm._l((_vm.errores.nota),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }