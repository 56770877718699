<template>
  <b-modal
    id="modal-from-apertura-caja"
    ref="modal"
    :title="tituloModal"
    v-model="modalFromMovimientoCaja"
    no-close-on-backdrop
    no-close-on-esc
    ok-title="Aceptar"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    button-size="sm"
    scrollable
    size="lg"
    @cancel="cerrarModal"
    @close="cerrarModal"
    @ok.prevent="validar"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mb-0-5">
          <b-col cols="12" md="3">
            <b-form-group label="* TIPO " label-for="tipo">
              <validation-provider
                #default="{ errors }"
                name="tipo"
                rules="required"
              >
                <b-form-select
                  id="tipo"
                  v-model="form.tipo"
                  :options="tipoMovimientoItems"
                  size="sm"
                  @change="getPartidas()"
                  :state="errors.length > 0 ? false : null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="9">
            <b-form-group label="* CONCEPTO " label-for="concepto">
              <validation-provider
                #default="{ errors }"
                name="concepto"
                rules="required"
              >
                <v-select
                  v-model="form.clasificador_id"
                  class="style-chooser select-size-sm is-invalid error"
                  label="nombre"
                  :options="clasificadorItems"
                  :key="form.clasificador_id"
                  :reduce="(op) => op.id"
                  :class="{ 'is-invalid': errors && errors.length > 0 }"
                  :selectable="(option) => !option.disabled"
                  @input="getDetalle()"
                >
                  <template v-slot:option="option">
                    <span v-html="option.descripcion_tabulada"></span>
                  </template>
                </v-select>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="12">
            <b-form-group label="* DETALLE" label-for="detalle" label-cols="12">
              <validation-provider
                #default="{ errors }"
                name="Detalle"
                rules="required"
              >
                <b-form-input
                  id="detalle"
                  disabled
                  size="sm"
                  :value="form.detalle"
                  :state="errors.length > 0 ? false : null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-0-5">
          <b-col cols="12" md="6">
            <b-form-group label="* MONTO " label-for="monto">
              <validation-provider
                #default="{ errors }"
                name="monto"
                :rules="
                  form.tipo == 'EGRESO'
                    ? `max_value:${totalEfectivoCaja}`
                    : 'required'
                "
              >
                <b-form-input
                  id="monto"
                  type="number"
                  step="any"
                  :disabled="!form.tipo"
                  lazy-formatter
                  :formatter="numberFormatter"
                  :state="errors.length > 0 ? false : null"
                  onClick="this.select();"
                  v-model="form.monto"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group
              label="* TOTAL EFECTIVO CAJA "
              label-for="totalEfectivoCaja"
            >
              <b-form-input
                id="totalEfectivoCaja"
                type="number"
                step="any"
                disabled
                lazy-formatter
                :formatter="numberFormatter"
                :value="totalEfectivoCaja"
                size="sm"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group
              label="* TRANSACCION"
              label-for="transaccion"
              label-cols="12"
            >
              <validation-provider
                #default="{ errors }"
                name="Transaccion"
                rules="required|min:4"
              >
                <b-form-input
                  id="transaccion"
                  disabled
                  size="sm"
                  :value="form.transaccion"
                  lazy-formatter
                  :formatter="textFormatter"
                  :state="errors.length > 0 ? false : null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-0-5">
          <b-col cols="12" md="6" lg="12">
            <b-form-group label="NOTA" label-for="nota" label-cols="12">
              <validation-provider
                #default="{ errors }"
                name="Nota"
                rules="max:250"
              >
                <b-form-textarea
                  id="nota"
                  size="sm"
                  v-model="form.nota"
                  lazy-formatter
                  :formatter="textFormatter"
                  :state="errors.length > 0 ? false : null"
                  rows="10"
                />
                <small
                  v-for="error in errores.nota"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BAlert,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BFormRadioGroup,
  BFormCheckbox,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BFormTextarea,
  BCardText,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import vSelect from "vue-select";
import CajaServices from "../services/index";
import ClasificadorServices from "@/modules/configuracion/clasificador/services/index";

export default {
  components: {
    BButton,
    BModal,
    BAlert,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BFormRadioGroup,
    BFormCheckbox,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BFormTextarea,
    BCardText,
    BCard,
    vSelect,
  },
  props: [],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        id: null,
        clasificador_id: null,
        tipo: null,
        monto: parseFloat(0).toFixed(2),
        transaccion: "MOVIMIENTO DE CAJA",
        detalle: "",
        nota: "",
        caja_id: null,
      },
      totalEfectivoCaja: 0,
      clasificadorItems: [],
      tipoMovimientoItems: [
        { value: "EGRESO", text: "SALIDA" },
        { value: "INGRESO", text: "ENTRADA" },
      ],
      required,
      submitted: false,
      errores: [],

      modalFromMovimientoCaja: false,
    };
  },
  computed: {
    tituloModal() {
      return "MOVIMIENTO DE CAJA";
    },
  },
  watch: {},
  methods: {
    async validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            this.registrar();
          }
        }
      });
    },
    abrir() {
      this.getEfectivoCaja();
      this.modalFromMovimientoCaja = true;
      this.form.id = null;
      this.form.sede_id = this.mixing.sede_id;
      this.form.usuario_id = this.mixing.id;
    },
    cerrarModal() {
      this.limpiarForm();
      this.$emit("actualizarCajaApertura");
      this.submitted = false;
    },
    actualizarCajaApertura() {
      this.limpiarForm();
      this.$emit("actualizarCajaApertura");
      this.submitted = false;
    },
    async registrar() {},
    // limpiar
    limpiarForm() {
      this.form = {
        id: null,
        clasificador_id: null,
        tipo: null,
        monto: parseFloat(0).toFixed(2),
        transaccion: "MOVIMIENTO DE CAJA",
        detalle: "",
        nota: "",
      };
      this.modalFromMovimientoCaja = false;
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    async getEfectivoCaja() {
      try {
        const response = await CajaServices.efectivoCaja(
          this.mixing.caja ? this.mixing.caja.id : null
        );
        this.totalEfectivoCaja = parseFloat(response.data).toFixed(2);
      } catch (err) {
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    getDetalle() {
      if (this.form.clasificador_id) {
        const elemento = this.clasificadorItems.find(
          (item) => item.id === this.form.clasificador_id
        );
        this.form.detalle = elemento.nombre;
      } else {
        this.form.detalle = "";
      }
    },
    async getPartidas() {
      const tipo = this.form.tipo == "EGRESO" ? "Gastos" : "Ingresos";
      this.form.clasificador_id = null;
      this.form.detalle = "";
      try {
        const response = await ClasificadorServices.getTipoPartida(tipo);
        const partidas = response.data;
        const partidasAplanadas = [];
        this.formatearPartidas(partidas, partidasAplanadas, "", 0);
        this.clasificadorItems = partidasAplanadas;
      } catch (err) {
        console.log(err);
      }
    },
    formatearPartidas(partidas, partidasAplanadas, nivel, nivelaux) {
      nivel += "&nbsp;&nbsp;&nbsp;";
      nivelaux += 1;

      partidas.forEach((partida) => {
        partidasAplanadas.push({
          ...partida,
          descripcion_tabulada: `${nivel} ${partida.nombre}`,
          // descripcion_tabulada: `${nivel}${partida.codigo} ${partida.nombre}`,
          disabled: !partida.acepta_registro,
          nivel: nivelaux,
          nombre: `${partida.nombre}`,
          // nombre: `${partida.codigo} ${partida.nombre}`,
          codigo: partida.codigo,
        });

        this.formatearPartidas(
          partida.hijos,
          partidasAplanadas,
          nivel,
          nivelaux
        );
      });
    },
    async registrar() {
      this.form.caja_id = this.mixing.caja.id;
      try {
        await CajaServices.movimientoCaja(this.form);
        this.actualizarCajaApertura();
        this.errores = [];
        this.submitted = false;
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.border-right {
  border-right: 1px solid #ebe9f1;
}
.scroll-area-historia-clinica-lab {
  position: relative;
  margin: 0rem;
  width: 100%;
  padding: 0rem;
  height: calc(var(--vh, 1vh) * 100 - 27rem);
}
@media (max-width: 992px) {
  .border-right {
    border-right: 0px !important;
  }
  .scroll-area-historia-clinica-lab {
    height: auto;
  }
}
</style>