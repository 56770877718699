import axiosIns from "@/libs/axios";

const get = async (tipoPartida) => {
  return await axiosIns.get(`clasificadores?tipo_partida=${tipoPartida}`)
}

const store = async (data) => {
  return await axiosIns.post('clasificadores', data)
}

const update = async (id, data) => {
  return await axiosIns.put(`clasificadores/${id}`, data)
}

const destroy = async (id) => {
    return await axiosIns.delete(`clasificadores/${id}`)
}

const getTipoPartida = async (tipo_partida) => {
  return await axiosIns.get(`clasificadores/subclasificadores?tipo_partida=${tipo_partida}`)
}
  
export default {
  get,
  store,
  update,
  destroy,
  getTipoPartida,
}