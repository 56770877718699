<template>
  <b-modal
    id="modal-from-apertura-caja"
    ref="modal"
    :title="tituloModal"
    v-model="modalFromAperturaCaja"
    no-close-on-backdrop
    no-close-on-esc
    ok-title="Aceptar"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    button-size="sm"
    scrollable
    @cancel="cerrarModal"
    @close="cerrarModal"
    @ok.prevent="validar"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group label="USUARIO/CAJERO:" label-cols-xl="5">
              <b-form-input size="sm" :value="mixing.nombre" disabled />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group label="FECHA INCIO:" label-cols-xl="5">
              <b-form-input size="sm" :value="mixing.hoy" disabled />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group label="BASE EFECTIVO:" label-cols-xl="5">
              <validation-provider
                #default="{ errors }"
                name="Costo de consulta"
                rules="positive"
              >
                <b-form-input
                  type="number"
                  step="any"
                  size="sm"
                  lazy-formatter
                  :formatter="numberFormatter"
                  :state="errors.length > 0 ? false : null"
                  onClick="this.select();"
                  v-model="form.monto"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import store from "@/store/index";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BAlert,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BFormRadioGroup,
  BFormCheckbox,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BFormTextarea,
  BCardText,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import CajaServices from "../services/index";
export default {
  components: {
    BButton,
    BModal,
    BAlert,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BFormRadioGroup,
    BFormCheckbox,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BFormTextarea,
    BCardText,
    BCard,
  },
  props: ["accionModalCajaApertura", "itemApertura"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        id: null,
        monto: 0,
      },
      required,
      submitted: false,
      errores: [],

      modalFromAperturaCaja: false,
    };
  },
  computed: {
    tituloModal() {
      return this.form.id
        ? "MODIFICAR INICIO DE OPERACION"
        : "REGISTRO DE INICIO DE OPERACION";
    },
  },
  watch: {
    accionModalCajaApertura: function () {
      this.errores = [];
      if (this.accionModalCajaApertura) {
        this.abrir(this.accionModalCajaApertura, this.itemApertura);
      }
    },
  },
  methods: {
    async validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.form.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
    abrir(accion, data = []) {
      switch (accion) {
        case "aperturar": {
          this.modalFromAperturaCaja = true;
          this.form.id = null;
          this.form.sede_id = this.mixing.sede_id;
          this.form.usuario_id = this.mixing.id;
          this.form.monto = 0;
          break;
        }
        case "actualizar": {
          this.modalFromAperturaCaja = true;
          this.form.sede_id = data.sede_id;
          this.form.usuario_id = data.usuario_id;
          this.form.id = data.id;
          this.form.monto = data.monto;
          break;
        }
      }
    },
    cerrarModal() {
      this.limpiarForm();
      this.$emit("cerrarModalCajaApertura");
    },
    actualizarCajaApertura() {
      this.limpiarForm();
      this.$emit("actualizarCajaApertura");
      this.submitted = false;
    },
    async registrar() {
      try {
        await CajaServices.store(this.form);
        await store
          .dispatch("auth/attempt", localStorage.getItem("token"))
          .then(() => {});
        this.actualizarCajaApertura();
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        await store
          .dispatch("auth/attempt", localStorage.getItem("token"))
          .then(() => {});
        this.actualizarCajaApertura();
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar() {
      try {
        const response = await CajaServices.update(this.form.id, this.form);
        await store
          .dispatch("auth/attempt", localStorage.getItem("token"))
          .then(() => {});
        this.actualizarCajaApertura();
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        await store
          .dispatch("auth/attempt", localStorage.getItem("token"))
          .then(() => {});
        this.actualizarCajaApertura();
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },

    // limpiar
    limpiarForm() {
      this.form.id = null;
      this.form.monto = 0;
      this.form.sede_id = "";
      this.form.usuario_id = "";
      this.modalFromAperturaCaja = false;
    },

    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.border-right {
  border-right: 1px solid #ebe9f1;
}
.scroll-area-historia-clinica-lab {
  position: relative;
  margin: 0rem;
  width: 100%;
  padding: 0rem;
  height: calc(var(--vh, 1vh) * 100 - 27rem);
}
@media (max-width: 992px) {
  .border-right {
    border-right: 0px !important;
  }
  .scroll-area-historia-clinica-lab {
    height: auto;
  }
}
</style>