<template>
  <b-overlay :show="loading">
    <ag-grid-vue
      :gridOptions="gridOptions"
      class="ag-theme-balham ag-grid-table-caja"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      @grid-ready="onGridReady"
      :rowData="items"
      colResizeDefault="shift"
      :animateRows="false"
      :floatingFilter="false"
      :pagination="false"
      :rowClassRules="rowClassRules"
      @selection-changed="onSelectionChanged"
      @first-data-rendered="onFirstDataRendered"
      :alwaysShowVerticalScroll="true"
      :suppressPaginationPanel="true"
    >
    </ag-grid-vue>
  </b-overlay>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { BCard, BCardText, BOverlay } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardText,
    AgGridVue,
    BOverlay,
  },
  props: ["items", "loading"],
  data() {
    return {
      gridOptions: {
        localeText: {
          noRowsToShow: "No hay filas para mostrar",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,

      gridApi: null,
      selectedRows: [],
      defaultColDef: {
        sortable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "FECHA CREACION",
          field: "fecha",
          filter: false,
          autoHeight: true,
          checkboxSelection: false,
          headerCheckboxSelectionFilteredOnly: false,
          headerCheckboxSelection: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "FECHA ACTUALIZACION",
          field: "actualizado",
          filter: false,
          autoHeight: true,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "CAJERO",
          field: "caja.usuario.nombre",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "TIPO",
          field: "tipo",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "METODO",
          field: "metodo_pago.descripcion",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "TRANSACCION",
          field: "transaccion",
          filter: false,
          minWidth: 300,
          maxWidth: 300,
        },
        {
          headerName: "DETALLE",
          field: "detalle",
          filter: false,
          autoHeight: true,
          minWidth: 300,
          maxWidth: 300,
          cellStyle: { "white-space": "normal" },
        },
        {
          headerName: "NOTA",
          field: "nota",
          filter: false,
          autoHeight: true,
          minWidth: 220,
          cellStyle: { "white-space": "normal" },
        },
        {
          headerName: "S. ENTRADA",
          field: "saldo_entrada",
          type: "numericColumn",
          valueGetter: (params) => {
            return params.data.saldo_entrada ? params.data.saldo_entrada : "";
          },
          filter: false,
          autoHeight: true,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "S. SALIDA",
          field: "saldo_salida",
          type: "numericColumn",
          valueGetter: (params) => {
            return params.data.saldo_salida ? params.data.saldo_salida : "";
          },
          filter: false,
          autoHeight: true,
          minWidth: 100,
          maxWidth: 100,
        },
      ],
      rowClassRules: { "row-red": "" },
      searchQuery: "",
    };
  },
  created() {},
  methods: {
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onSelectionChanged() {
      let selectedRows = this.gridApi.getSelectedRows();
      this.selectedRows = selectedRows;
      if (this.selectedRows != "") {
        this.$emit("selectItemCaja", this.selectedRows[0], false);
      } else {
        this.selectedRows = [];
        this.$emit("selectItemCaja", this.selectedRows, true);
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/agGridStyle.scss";
.ag-theme-balham .row-red {
  color: #ea5455 !important;
}
</style>
