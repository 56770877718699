import axiosIns from "@/libs/axios";

const get = async (caja_id) => {
  return await axiosIns.get(`cajas?&caja_id=${caja_id}`)
}

const store = async (data) => {
  return await axiosIns.post('cajas', data)
}

const update = async (id, data) => {
  return await axiosIns.patch(`cajas/${id}`, data)
}

const getCierreCaja = async (caja_id) => {
  return await axiosIns.get(`cajas/cierre-caja?&caja_id=${caja_id}`)
}

const cierreCaja = async (data) => {
  return await axiosIns.post('cajas/cierre-caja', data)
}

const volverAbrirCaja = async (id) => {
  return await axiosIns.delete(`cajas/${id}`)
}

const efectivoCaja = async (caja_id) => {
  return await axiosIns.get(`cajas/efectivo-caja?&caja_id=${caja_id}`)
}

const movimientoCaja = async (data) => {
  return await axiosIns.post('cajas/movimiento-caja', data)
}
  
export default {
  get,
  store,
  update,
  getCierreCaja,
  cierreCaja,
  volverAbrirCaja,
  efectivoCaja,
  movimientoCaja
}
